var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "common-bg login" },
    [
      _c("simple-header", { attrs: { hasRight: false } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "国考云隐私保护条例",
            visible: _vm.showPrivateRule,
            width: "600px",
            center: true,
            modal: false,
            "custom-class": "private-rule",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPrivateRule = $event
            },
          },
        },
        [_c("private-rule")],
        1
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.loginLoading,
            title: "提示",
            isShowFooter: false,
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.loginLoading = false
            },
          },
        },
        [
          _c("div", { staticClass: "loading-container" }, [
            _c("div", { staticClass: "loading" }, [
              _c("div", { staticClass: "lds-default" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ]),
            ]),
            _c("span", [_vm._v("系统登录中，请稍后...")]),
          ]),
        ]
      ),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.shortName
                    ? _vm.shortName + _vm.userExamName + _vm.scenario
                    : `在线考试`
                ) +
                " "
            ),
          ]),
          !!_vm.time
            ? _c("div", { staticClass: "exam-time" }, [
                _vm._v(
                  " 考试时间：" +
                    _vm._s(
                      _vm.time.examStartAt
                        .substring(0, _vm.time.examStartAt.length - 3)
                        .replace(/-/g, "/")
                    ) +
                    " - " +
                    _vm._s(
                      _vm.time.examEndAt
                        .substring(0, _vm.time.examEndAt.length - 3)
                        .replace(/-/g, "/")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "welcome" }, [
            _vm._v("欢迎你参加" + _vm._s(_vm.scenario) + "！"),
          ]),
          _c("div", { staticClass: "tips" }, [
            _c("div", { staticClass: "tip" }, [
              _vm._v(
                "请使用最新版Chrome浏览器进行" +
                  _vm._s(_vm.category === 3 ? "面试" : "考试") +
                  "；"
              ),
            ]),
            _c("div", { staticClass: "tip" }, [
              _vm._v("提前登录系统，检测调试电脑及手机设备；"),
            ]),
            _c("div", { staticClass: "tip" }, [
              _vm._v(
                "请关闭QQ微信等软件，不要跳出" +
                  _vm._s(_vm.category === 3 ? "面试" : "考试") +
                  "页面；"
              ),
            ]),
            _c("div", { staticClass: "tip" }, [
              _vm._v(
                "注意" +
                  _vm._s(_vm.category === 3 ? "面试" : "考试") +
                  "纪律，诚信作答；"
              ),
            ]),
            _c("div", { staticClass: "tip" }, [
              _vm._v(
                "网络故障中断" +
                  _vm._s(_vm.category === 3 ? "面试" : "考试") +
                  "，重新登录继续作答即可。"
              ),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !!_vm.time && _vm.resetSec > 0,
                  expression: "!!time && resetSec > 0",
                },
              ],
              staticClass: "left-time",
            },
            [
              _vm.second >= 0
                ? _c("span", { staticClass: "count-down-title" }, [
                    _vm._v(" 距离开考还有："),
                  ])
                : _vm._e(),
              _vm.day > 0
                ? _c("span", { staticClass: "count-down" }, [
                    _vm._v(_vm._s(_vm.day) + " "),
                    _c("span", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v("天"),
                    ]),
                  ])
                : _vm.day <= 0 && _vm.hour > 0
                ? _c("span", { staticClass: "count-down" }, [
                    _vm._v(_vm._s(_vm.hour) + " "),
                    _c("span", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v("小时"),
                    ]),
                  ])
                : _vm.hour <= 0 && _vm.minute > 0
                ? _c("span", { staticClass: "count-down" }, [
                    _vm._v(_vm._s(_vm.minute) + " "),
                    _c("span", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v("分钟"),
                    ]),
                  ])
                : _vm.minute <= 0 && _vm.second > 0
                ? _c("span", { staticClass: "count-down" }, [
                    _vm._v(_vm._s(_vm.second) + " "),
                    _c("span", { staticStyle: { "font-size": "16px" } }, [
                      _vm._v("秒"),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "right login-box" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("用户登录")]),
            _c(
              "el-form",
              {
                ref: "loginForm",
                staticClass: "login-form",
                attrs: { model: _vm.loginForm, rules: _vm.rules },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "loginName" } },
                  [
                    _c("el-input", {
                      attrs: {
                        disabled:
                          !_vm.loginForm.loginName.startsWith("localhost"),
                        placeholder: "请输入账号",
                      },
                      model: {
                        value: _vm.loginForm.loginName,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "loginName", $$v)
                        },
                        expression: "loginForm.loginName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "loginPassword" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { type: _vm.pwdType, placeholder: "请输入密码" },
                        model: {
                          value: _vm.loginForm.loginPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "loginPassword", $$v)
                          },
                          expression: "loginForm.loginPassword",
                        },
                      },
                      [
                        !_vm.flag
                          ? _c("i", {
                              staticClass: "iconfont iconeye",
                              attrs: { slot: "suffix", autocomplete: "auto" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeFlag("text")
                                },
                              },
                              slot: "suffix",
                            })
                          : _c("i", {
                              staticClass: "iconfont iconno_eye",
                              attrs: { slot: "suffix", autocomplete: "auto" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeFlag("password")
                                },
                              },
                              slot: "suffix",
                            }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "code", attrs: { prop: "imageCode" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入验证码" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleLogin.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.loginForm.imageCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "imageCode", $$v)
                        },
                        expression: "loginForm.imageCode",
                      },
                    }),
                    _c("span", { staticClass: "line" }),
                    _c("img", {
                      staticClass: "code-img",
                      attrs: { src: _vm.codeBase64, alt: "验证码" },
                      on: { click: _vm.imageValidationCode },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("el-checkbox", {
                      model: {
                        value: _vm.privateRule,
                        callback: function ($$v) {
                          _vm.privateRule = $$v
                        },
                        expression: "privateRule",
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "private-rule-label",
                        on: {
                          click: function ($event) {
                            _vm.showPrivateRule = true
                          },
                        },
                      },
                      [_vm._v("国考云隐私保护条例")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "login-btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleLogin },
                  },
                  [_vm._v("登录")]
                ),
                _c("router-link", {
                  staticClass: "check",
                  attrs: {
                    target: "_blank",
                    custom: "",
                    to: {
                      path: "DeviceDetection",
                      query: {
                        shortId: _vm.$route.query.shortId,
                      },
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ href }) {
                        return [
                          _c(
                            "a",
                            {
                              staticClass: "link",
                              attrs: { href: href, role: "link" },
                            },
                            [
                              _c(
                                "i",
                                { staticClass: "iconfont iconshexiangtou2" },
                                [
                                  _c("span", { staticClass: "link-content" }, [
                                    _vm._v("设备网络检测"),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("simple-footer", { attrs: { shortName: _vm.shortName } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }